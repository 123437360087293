import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layouts/layout"
import * as myModule from "../components/dynamic"
import Seo from 'gatsby-plugin-wpgraphql-seo'

function loanSingle({ data }) {
  const wpData = data.wpLoan.sections.blocks
  const seo = data?.wpLoan?.seo
  return (
    <Layout>
      <Seo post={data.wpLoan} />
      <div className="find-load-wrapper" id="loan-single-page">
        {wpData?.map(row => {
          // assign component math with name
          const Component = myModule[row.fieldGroupName.split("_").slice(-1)[0]]
          return <Component key={row.fieldGroupName} {...row} />
        })}
      </div>
    </Layout>
  )
}

export default loanSingle

export const query = graphql`
  query($slug: String!) {
    wpLoan(slug: { eq: $slug }) {
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      title
      slug
      content
      sections {
        blocks {
          ... on WpLoan_Sections_Blocks_PageHeader {
            fieldGroupName
            title
            subtitle
            box {
              title
              link {
                title
                url
                target
              }
            }
          }
          ... on WpLoan_Sections_Blocks_ShiftedColumnsLoans {
            fieldGroupName
            col1 {
              blocks {
                theme
                title
                content
                buttons {
                  link {
                    title
                    url
                    target
                  }
                }
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
            col2 {
              blocks {
                theme
                title
                content
                buttons {
                  link {
                    title
                    url
                    target
                  }
                }
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
          ... on WpLoan_Sections_Blocks_ContentCentered {
            fieldGroupName
            title
            subtitle
            backgroundColor
            content
            buttons {
              button {
                title
                url
                target
              }
            }
            background {
              sourceUrl
            }
          }
          ... on WpLoan_Sections_Blocks_Cta2 {
            fieldGroupName
            title
            content
          }
          ... on WpLoan_Sections_Blocks_Cta1 {
            fieldGroupName
            title
            content
            backgroundImage {
              sourceUrl
            }
            buttons {
              link {
                url
                title
                target
              }
            }
          }
          ... on WpLoan_Sections_Blocks_ApplyNow {
            fieldGroupName
            title
            officers {
              ... on WpLoanOfficer {
                title
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
                officers {
                  position
                  nmls
                  hixonLendingNmls
                  licensedIn
                  phone {
                    title
                  }
                  email {
                    title
                  }
                  buttons {
                    link {
                      target
                      title
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
